@value (
  primary-color, on-primary-color,
  secondary-color, on-secondary-color
) from "../colors.module.css";

@value (
  breakpoint-sm, breakpoint-md, breakpoint-lg
) from "../breakpoints.module.css";

.navigation {
  font-family: var(--theme-widget-font-family);
  composes: typography-navigation from global;

  background-color: primary-color;
  color: on-primary-color;

  position: sticky;
  top: 0;
  z-index: 11;
  padding: space(4) 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobileMenuOpen::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: primary-color;
  z-index: -1;
}

.mobileMenuOpen .list,
.mobileMenuOpen .toggleLocale {
  display: flex;
  animation: slide-from-top 0.5s 1 ease;
}

.toggleLocale,
.wrapper {
  margin: 0 8%;
  width: 84%;
  max-width: var(--theme-section-max-width);
}

.toggleLocale {
  composes: typography-navigationToggleLocale from global;
  display: none;
  order: 1;
  justify-content: center;
  align-items: center;
  margin-top: space(20);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.localeLink {
  color: on-primary-color;
  text-decoration: none;
  font-weight: bold;
}

.locale {
  padding: space(3) space(2);
}

.localeDivider {
  margin: 0 space(1);
}

.logo {
  width: 110px;
  display: block;
}

.list {
  display: none;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: space(10) 0 0;

  width: 100%;
  text-align: center;
}

.link {
  display: inline-block;
  color: on-primary-color;
  text-decoration: none;
  padding: space(1) space(4) space(1.5);
  margin-top: space(5);
  font-weight: bold;
  position: relative;
  white-space: nowrap;
}

.activeLink::after {
  content: "";
  position: absolute;
  left: space(4);
  right: space(4);
  bottom: 0;
  border-bottom: solid 2px #fff;
}

.mobileMenuButton {
  border: 0;
  background-color: transparent;
  background-image: url('../images/icons/bars.svg');
  background-position: center;
  width: size(7);
  height: size(7);
}

.mobileMenuOpen .mobileMenuButton {
  background-image: url('../images/icons/xmark.svg');
}

@media breakpoint-sm {
  .navigation {
    padding: space(3) 0;
    top: -1.875rem;
  }

  .wrapper {
    flex-wrap: nowrap;
  }

  .logo {
    transform: translateX(-10%);
  }

  .mobileMenuOpen::before,
  .mobileMenuButton {
    display: none;
  }

  .toggleLocale,
  .list {
    display: flex;
  }

  .toggleLocale {
    display: flex;
    order: 0;
    justify-content: flex-end;
    padding-bottom: space(2);
    margin-top: 0;
  }

  .toggleLocale::before {
    content: url("../images/icons/globe-solid.svg");
    height: 18px;
    margin: 0 space(1);
  }

  .locale {
    padding: 0;
  }

  .list {
    flex-direction: row;
    margin: space(3) 0 space(4);
    width: auto;
    align-items: baseline;
  }

  .link {
    margin-top: 0;
  }
}

@media breakpoint-md {
  .logo {
    width: 128px;
    margin-right: 100px;
  }
}

@media breakpoint-lg {
  .logo {
    width: 170px;
  }

  .link {
    margin: 0 space(4);
  }

  .activeLink::after {
    border-width: 3px;
  }
}

.login {
  color: secondary-color;
}

.register {
  composes: typography-navigationSm from global;
  background-color: secondary-color;
  color: on-secondary-color;
  border-radius: rounded(md);
  margin-right: 0;
}

.register:hover:not(:active) {
  opacity: 0.92;
}

@keyframes slide-from-top {
  0% {
    opacity: 0;
    transform: translateY(-4rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
